import React from "react"
import Layout from "../components/layout"
import Footer from "../components/footer";
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';


import '../styles/homeStyles.scss'
import blueWing from '../assets/img/blue-wing.svg'
import greenWing from '../assets/img/green-wing.svg'
import { motion } from "framer-motion";

const IndexPage = props => {
  return <Layout>
             <Helmet>
                <title>Next.Legal | Homepage</title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://yourapp.com" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal | Homepage" />
                <meta property="og:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="twitter:title" content="Next.Legal | Homepage" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
             </Helmet>
            
            <div className="main-container">
                <div className="title-container">
                  
                      <motion.div 
                      className="title"
                     /*  animate={{ y: 0,  }}
                      initial={{ y: 72, }} */
                      animate={{y:0 , opacity: 1}}
                      initial={{ y: 72, opacity: 0 }}
                      transition={{
                        duration: 0.5,
                        ease: [0.6, 0.05, -0.01, 0.9],
                        /* staggerChildren: 0.1 */
                      }}
                      
                      >
                           <motion.h1
                           /*  animate={{y:0 , opacity: 1}}
                            initial={{ y: 72, opacity: 0 }} */
                           
                    >Tomorrow’s Legal today.</motion.h1>
                            <motion.p 
                            className="nxt-copy"
                           /*  animate={{y:0 , opacity: 1}}
                            initial={{ y: 72, opacity: 0 }} */
                            >
                              Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem. Next. Legal Summit 2.0 will happen on October 23rd, 2020.</motion.p>
                            <Link
                              to="/about"
                            >
                            <motion.button 
                              /* animate={{y:0 , opacity: 1}}
                              initial={{ y: 52, opacity: 0 }} */
                              className="px-6 py-2 text-white font-light tracking-wider bg-gray-900 rounded-full learn-more">
                              Learn more</motion.button>
                            </Link>
                            <Link
                              to="/register/"
                            >
                            <motion.button 
                            /* animate={{y:0 , opacity: 1}}
                            initial={{ y: 52, opacity: 0 }} */
                            className="px-4 py-2 text-white font-light tracking-wider bg-gray-900 rounded-full register-home"
                            >Register</motion.button>
                            </Link>
                      </motion.div>
                      <motion.div 
                        className="lex-wings"
                        transition={{
                          duration: 1,
                          ease: [0.6, 0.05, -0.01, 0.9],
                          staggerChildren: 0.1
                        }}
                        >
                        <motion.img 
                        animate={{y:0 , opacity: 1}}
                        initial={{ y: 72, opacity: 0 }}
                        transition={{
                          duration: 1,
                          ease: [0.6, 0.05, -0.01, 0.9],
                  
                        }}
                        className="blueWing" src={blueWing} alt="bytelex blue wing"/>
                        <motion.img 
                        animate={{y:0 , opacity: 1}}
                        initial={{ y: 72, opacity: 0 }}
                        transition={{
                          duration: 1,
                          ease: [0.6, 0.05, -0.01, 0.9],
                          
                        }}
                        className="greenWing" src={greenWing} alt="bytelex green wing"/>
                      </motion.div>
                    
                </div>
            </div>

        <Footer/> 
        </Layout>
}

export default IndexPage
